.tabs {
    position: relative;
    margin: 0 0 30px 0;
}

ul {
    &.tab-nav {
        margin: 0;
        border-bottom: 1px solid #DDD;
        list-style: none;
        li {
            float: left;
            border: 1px solid #DDD;
            border-bottom: 0;
            border-left: 0;
            height: 41px;
            text-align: center;
            &:first-child {
                margin-left: 15px;
                border-left: 1px solid #DDD;
            }
            a {
                display: block;
                padding: 0 15px;
                color: #444;
                height: 40px;
                line-height: 40px;
                background-color: #F2F2F2;
                font-size: 14px;
                font-weight: bold;
            }
            &.ui-tabs-active {
                a {
                    position: relative;
                    top: 1px;
                    background-color: #FFF;
                }
            }
        }
    }
}
.tab-content {
    padding: 5px;
    p {
        margin-bottom: .5rem !important;
        font-size: 14px !important;
    }
    ul {
        padding-left: 18px;
        list-style: square;
    }
}
.tabs {
    .icon {
        width: 32px;
        height: 32px;
        vertical-align: middle;
        padding-bottom: 2px;
    }
    span {
        padding-left: 15px;
    }
    .ui-state-default {
        .icon {
            fill: #555;
        }
    }
    .ui-state-active {
        .icon {
            fill: #fff;
        }
    }
}

/* Tabs - Alternate Nav
-----------------------------------------------------------------*/
.tabs {
    &.tabs-alt {
        ul.tab-nav li {
            border-color: transparent;
            &:first-child { border-left: 0; }
            a { background-color: #FFF; }
            &.ui-tabs-active a {
                border: 1px solid #DDD;
                border-bottom: 0;
            }
        }
    }
}

/* Tabs - Top Border
-----------------------------------------------------------------*/

.tabs {
    &.tabs-tb {
        ul.tab-nav {
            li.ui-tabs-active a {
                top: -1px;
                height: 43px;
                line-height: 38px;
                border-top: 2px solid $theme-color;
            }
        }
    }
    &.tabs-alt {
        &.tabs-tb {
            ul.tab-nav {
                li.ui-tabs-active a { height: 42px; }
            }
        }
    }
}

/* Tabs - Bottom Border
-----------------------------------------------------------------*/

.tabs {
    &.tabs-bb {
        ul.tab-nav {
            li {
                border: 0;
                margin: 0;
                a {
                    background-color: transparent;
                    border-bottom: 2px solid transparent;
                }
                &.ui-tabs-active a {
                    border-bottom: 2px solid $theme-color;
                    top: -2px;
                    height: 44px;
                    line-height: 44px;
                }
            }
        }
    }
}

/* Tabs - Navigation Style 2
-----------------------------------------------------------------*/

ul {
    &.tab-nav {
        &.tab-nav2 {
            border-bottom: 0;
            li {
                border: 0;
                margin-left: 10px;
                &:first-child {
                    margin-left: 0;
                    border-left: 0;
                }
                a {
                    background-color: #F5F5F5;
                    @include border-radius(2px);
                    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
                }
                &.ui-state-active {
                    a {
                        top: 0;
                        background-color: $theme-color;
                        color: #FFF;
                    }
                }
            }
        }
    }
}

/* Tabs - Content Area
-----------------------------------------------------------------*/

.tab-container {
    position: relative;
    padding: 20px 0 0;
}

ul {
    &.tab-nav {
        li {
            a {
                i {
                    position: relative;
                    top: 1px;
                    font-size: 14px;
                    margin-right: 3px;
                }
            }
        }
    }
}

/* Tabs - Bordered
-----------------------------------------------------------------*/

.tabs-bordered {
    ul {
        &.tab-nav {
            li {
                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
    .tab-container {
        border: 1px solid #DDD;
        border-top: 0;
        padding: 20px;
    }
}

/* Tabs - Side Tabs
-----------------------------------------------------------------*/

.side-tabs {
    ul {
        &.tab-nav {
            float: left;
            width: 200px;
            padding: 20px 0;
            border-bottom: 0;
            border-right: 1px solid #DDD;
            li {
                float: none;
                border: 1px solid #DDD;
                border-right: 0;
                border-top: 0;
                height: auto;
                text-align: left;
                &:first-child {
                    margin-left: 0;
                    border-top: 1px solid #DDD;
                    border-left: 1px solid #DDD;
                }
                a {
                    height: auto;
                    line-height: 44px;
                }
                &.ui-tabs-active {
                    a {
                        top: 0;
                        right: -1px;
                    }
                }
            }
        }
    }
    .tab-container {
        overflow: hidden;
        padding: 15px 0 0 20px;
    }
}

/* Tabs - Side Tabs & Nav Style 2
-----------------------------------------------------------------*/

.side-tabs {
    ul {
        &.tab-nav {
            &.tab-nav2 {
                border: 0;
                li {
                    border: 0;
                    margin: 6px 0 0;
                    &:first-child {
                        margin-top: 0;
                    }
                    a {
                        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
                    }
                    &.ui-state-active {
                        a {
                            right: 0;
                        }
                    }
                }
            }
        }
    }
}

/* Tabs - Side Tabs & Bordered
-----------------------------------------------------------------*/

.tabs-bordered {
    &.side-tabs {
        ul {
            &.tab-nav {
                position: relative;
                padding: 0;
                z-index: 2;
                margin-right: -1px;
                li {
                    a {
                        height: auto;
                        line-height: 44px;
                    }
                    &.ui-tabs-active {
                        a {
                            right: -1px;
                            border-right: 1px solid #FFF;
                        }
                    }
                }
            }
        }
        .tab-container {
            overflow: hidden;
            padding: 20px;
            border-top: 1px solid #DDD;
        }
    }
}

/* ----------------------------------------------------------------
        Side Navigation
-----------------------------------------------------------------*/


.sidenav {
    width: 100%;
    padding: 0;
    background-color: #FFF;
    border-radius: 4px;
}

.sidenav li { list-style-type: none; }

.sidenav li a {
    display: block;
    position: relative;
    margin: 0 0 -1px;
    padding: 11px 20px 11px 14px;
    font-size: 14px;
    border: 1px solid #E5E5E5;
    border-right-width: 2px;
    color: #666;
}

.sidenav > li:first-child > a { border-radius: 4px 4px 0 0; }

.sidenav > li:last-child > a {
    border-radius: 0 0 4px 4px;
    border-bottom-width: 2px;
}

.sidenav > .ui-tabs-active > a,
.sidenav > .ui-tabs-active > a:hover {
    position: relative;
    z-index: 2;
    border-color: rgba(0,0,0,0.1) !important;
    color: #FFF !important;
    font-weight: 700;
    background-color: $theme-color !important;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
}

.sidenav li ul { display: none; }

.sidenav li.ui-tabs-active ul { display: block; }

.sidenav li li a {
    padding: 6px 20px 6px 35px;
    font-size: 14px;
    border-top: none;
    border-bottom: none;
}

.sidenav li li:first-child a { padding-top: 20px; }

.sidenav li li:last-child a { padding-bottom: 20px; }

.sidenav li i {
    position: relative;
    top: 1px;
    margin-right: 6px;
    font-size: 14px;
    text-align: center;
    width: 16px;
}

/* Chevrons */
.sidenav .icon-chevron-right {
    position: absolute;
    width: 14px;
    height: 14px;
    top: 50%;
    left: auto;
    right: 6px;
    margin-top: -8px;
    margin-right: 0;
    opacity: .4;
    font-size: 12px !important;
}

.sidenav > li > a:hover {
    background-color: #F5F5F5;
    color: #222;
}

.sidenav a:hover .icon-chevron-right { opacity: .5; }

.sidenav .ui-tabs-active .icon-chevron-right,
.sidenav .ui-tabs-active a:hover .icon-chevron-right { opacity: 1; }

@media screen and (max-width: 640px) {
    #content .content-wrap {
        position: relative;
        padding: 80px 0 20px;
    }
    .side-tabs ul.tab-nav.tab-nav2 {
        width: 100%;
    }
    .side-tabs .tab-container {
        width: 100%;
        padding: 0;
    }
}