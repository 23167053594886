/*-----------------------------------------------------------------------------------
    Theme Name: Gite Le Touquet
    Description: One Page Template
    Author: MCP Webdesign
    Author URI: https://mcpwebdesign.fr
    Version: 1.1
-----------------------------------------------------------------------------------*/
// Initialize
@import "variables";
@import "mixins";

// Core CSS
@import "typography";
@import "helpers";
@import "layouts";

// Content Blocks
@import "topbar";
@import "header";
//@import "sliders";
@import "pagetitle";
@import "content";
@import "portfolio";
//@import "blog";
//@import "shop";
//@import "events";

// Footer
@import "footer";

// Shortcodes
//@import "shortcodes/countdown";
@import "shortcodes/buttons";
@import "shortcodes/promo";
@import "shortcodes/feature-box";
@import "shortcodes/process-steps";
@import "shortcodes/alerts";
@import "shortcodes/styled-icons";
@import "shortcodes/social-icons";
@import "shortcodes/toggles-accordions";
@import "shortcodes/tabs";
//@import "shortcodes/faqs";
//@import "shortcodes/clients";
//@import "shortcodes/testimonials";
//@import "shortcodes/team";
@import "shortcodes/pricing";
@import "shortcodes/counter-skills";
@import "shortcodes/carousel";
@import "shortcodes/overlay";
@import "shortcodes/headings";
@import "shortcodes/dividers";
//@import "shortcodes/youtubebg";
@import "shortcodes/misc";

// Widgets
@import "widgets";

// Extras
@import "extras";

//@import "dark";
@import "responsive";

@import "custom";





