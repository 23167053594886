/* ----------------------------------------------------------------
        Content
-----------------------------------------------------------------*/
#content {
    position: relative;
    overflow: hidden;
    background-color: #FFF;

    p { line-height: $line-height-content; }

    .content-wrap {
        position: relative;
        padding: 80px 0;
    }

    .container { position: relative; }
}

.grid-container {
    position: relative;
    overflow: hidden;
}

body:not(.device-touch) .grid-container { @include transition(height .4s linear); }
